/*Text*/
.page-header {
    text-align: center;
    border-bottom: thin solid black;
}

.section-header {
    text-align: center;
    border-bottom: thin solid black;
}

.passage {
    padding-left: 3em;
    padding-right: 3em;
}

#signature {
    background-color: black;
    color: white;
    text-align: center;
}

/*Images*/
#autumn-owl_image-header {
    max-width: 100%;
}

#autumn-owl_image-icon {
    max-height: 12em;
    width: auto;
}

.product_image-thumbnail {
    max-height: 10em;
    width: auto;
    margin: 1em;
}

/*Container*/
#autumn-owl_section {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    padding: 2em;
    text-align: center;
}

#contact-us_section {
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    text-align: center;
}

.main-section {
    border: thin solid black;
    box-shadow: inset 0 0 1em 0em #000000;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
}

body {
    background-color: black;
    margin: 0;
    padding: 1em 1em 5em;
}

html {
    box-shadow: inset 0 0 1em 0em #CCCCCC;
}

#root {
    background-color: black;
}

#root > * {
    background-color: white;
}